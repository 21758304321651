import { AppContent } from "../AppContent";

const overpaymentsAllowanceAccountWarning: AppContent['overpaymentsAllowanceAccountWarning'] = {
  notificationHeading: "We can't show your ERC free overpayment allowance figure right now.",
  notificationSupportText1: "Call us on",
  contactNumber: "0345 300 2627",
  notificationSupportText2: "We're open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed on Sundays and bank holidays.",
  returnToMortgageOverviewLabel: "Return to mortgage overview"
}

export default overpaymentsAllowanceAccountWarning;