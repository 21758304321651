import { AppContent } from 'content/AppContent';

const overpayments: AppContent['overpayments'] = {
  title: 'How to make an overpayment',
  bankTransfer: {
    title: 'Pay by bank transfer',
    para1:
      "You can overpay your mortgage by 10% per calendar year without having to pay any early repayment charges (ERCs). We'll use your balance as of 01 January of the current calendar year to work this out.",
    para2:
      "If you've already made any one-off overpayments this calendar year or have any regular overpayments set up but not yet taken, you'll need to take these off your 10% allowance.",
    para3:
      'You can make one-off and regular overpayments by bank transfer using these details:',
    bankTitle: 'Lloyds Bank',
    bankSortCode: 'Sort code: ',
    bankSortCodeValue: '30-00-00',
    bankAccNo: 'Account number: ',
    bankAccNoValue: '00332275',
    bankName: 'Account name: ',
    bankNameValue: 'Lloyds Bank plc',
    bankRef: 'Reference: ',
    bankRefValue: "your 14-digit mortgage account number followed by '00'.",
    para4:
      "If you want to overpay a specific sub-account on your mortgage, replace '00' with the last two digits of the sub-account's number.",
  },
  phone: {
    title: 'Pay over the phone',
    para1A:
      'To make a one-off overpayment by phone, call our automated phone line on ',
    para1B: '0345 300 2627',
    para1C: '. The most you can overpay by phone in one go is ',
    para1D: '£60,000',
    para1E: ', but you can make more than one payment per day.',
    listItems: [
      'Please check your payment limits with your bank before making any large payments.',
      "You'll need your 14-digit mortgage account number to make the payment.",
      "Once you've made your overpayment, if you'd like to apply it to a specific sub-account, please call the same number to speak to us."
    ],
    para2:
      "We can only take debit card payments from customers named on the mortgage account. We don't accept credit cards or debit Mastercards (cards beginning with a 5).",
  },
};

export default overpayments;
