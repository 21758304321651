import React, { useState, useEffect } from "react";

import {
  Dialog,
  Heading,
  IconHome,
  IconCalculator,
  IconAdditionalBorrowing,
  Strong,
  Container,
  Paragraph,
  Hr,
  Button
} from "@constellation/core";
import { useContent } from "@interstellar/react-app-content";
import { logTealiumButtonClickEvent } from "client/tealium";

import { MortgageOverviewPageContent } from "../../MortgageOverviewPage.config";

const setCookie = (cookieName: string, valueToStore: string) => {
  document.cookie = `${cookieName}=${valueToStore}; max-age=${60 * 60 * 24 * 365}; path=/`
};

const getCookie = (cookieName: string) => {
  const match = document.cookie.match(new RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`));

  return match ? decodeURIComponent(match[1]) : null;
}

function WhatsNewDialog() {
  const { whatsNewDialog } = useContent<MortgageOverviewPageContent>();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  function logDialogClose() {
    logTealiumButtonClickEvent('button/whats-new-close');
  }

  useEffect(() => {
    if (getCookie(whatsNewDialog.cookieName) === whatsNewDialog.versionNumber) {
      setShowDialog(false);
    }
    else {
      setShowDialog(true);
      setCookie(whatsNewDialog.cookieName, whatsNewDialog.versionNumber);
    }
  }, [whatsNewDialog.cookieName, whatsNewDialog.versionNumber])

  const IconList: React.ReactElement[] = [
    <IconAdditionalBorrowing iconSize="lg" size="s4" trim color="brand" />,
    <IconHome iconSize="lg" size="s4" trim color="brand" />,
    <IconCalculator iconSize="lg" size="s4" trim color="brand" />
  ];

  if (showDialog) {
    return (
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          logDialogClose();
        }}>
        <Heading marginBottom="03">{whatsNewDialog.title}</Heading>

        {IconList.map((_, index) => (
          <React.Fragment key={whatsNewDialog.subheadings[index]}>
            <Container padding="none">
              {IconList[index]}
            </Container>
            <Strong>{whatsNewDialog.subheadings[index]}</Strong>
            <Paragraph marginBottom="03">{whatsNewDialog.paragraphs[index]}</Paragraph>

            {IconList.length > 1 && index !== IconList.length - 1 && <Hr marginTop="none" marginBottom="03" />}
          </React.Fragment>
        ))}

        <Button
          width="fluid"
          onClick={() => {
            setIsDialogOpen(false)
            logDialogClose();
          }}>
          {whatsNewDialog.buttonLabel}
        </Button>
      </Dialog>
    );
  }
  return null;

}

export default WhatsNewDialog;