import React from "react";

import { Strong, Text } from "@constellation/core";
import { useContent } from "@interstellar/react-app-content";
import { formatCurrency } from "client/routes/redemption/utils/formatCurrency";

import { SubAccountWithErcConcessionData } from "../../hooks/useERC"
import { StyledParagraph } from "../../OverpaymentsAllowance.styled";
import { OverpaymentsAllowanceContent } from "../../OverpaymentsAllowancePage.config";
import OverpaymentsAllowanceNotification from '../overpaymentsAllowanceNotification/OverpaymentsAllowanceNotification';

interface SingleSubAccountPanelProps {
  subAccount: SubAccountWithErcConcessionData;
}

function SingleSubAccountPanel({
  subAccount,
}: SingleSubAccountPanelProps): React.JSX.Element {
  const {
    accountPanel,
    notifications: { singleSubAccount },
  } = useContent<OverpaymentsAllowanceContent>();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <OverpaymentsAllowanceNotification
        ercAllowanceRemaining={subAccount.ercAllowanceRemaining}
        ercsApplyIndicator={subAccount.ercInd}
        overpaymentAllowedText={singleSubAccount.overpaymentAllowedText}
        overpayWholeBalanceText={singleSubAccount.overpayWholeBalanceText}
        usedAllowanceText={singleSubAccount.usedAllowanceText}
      />
      {subAccount.ercInd &&
        <>
          <StyledParagraph>
            <Text
              data-testid="single-subaccount-total-allowance-label">
              {accountPanel.totalAllowance}{' '}
              {subAccount.ercStartDate?.getFullYear()}:
            </Text>
            <Strong data-testid="single-subaccount-erc-allowance">
              {formatCurrency(subAccount.totalAllowance)}
            </Strong>
          </StyledParagraph>

          <StyledParagraph>
            <Text
              data-testid="single-subaccount-allowance-used-label">
              {accountPanel.allowanceUsed}</Text>
            <Strong data-testid="single-subaccount-erc-credits">
              {formatCurrency(subAccount.creditsInPeriod)}
            </Strong>
          </StyledParagraph>

          <StyledParagraph>
            <Text
              data-testid="single-subaccount-allowance-remaining-label">
              {accountPanel.allowanceRemainingLabel}</Text>
            <Strong data-testid="single-subaccount-erc-remaining">
              {formatCurrency(subAccount.ercAllowanceRemaining)}
            </Strong>
          </StyledParagraph>
        </>
      }
    </>
  );
}

export default SingleSubAccountPanel;
