import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  useRedemptionMutation,
  useOverviewMutation
} from 'client/services/api';
import { Overview } from 'client/services/types/overview';
import {
  ERCConcessionAmountDetail,
  Redemption,
} from 'client/services/types/redemption';
import { useAppSelector } from 'client/store';

const UNABLE_TO_SHOW_ERROR_CODES = [
  'E0003',
  'E0004',
  'E0005',
  'E0006',
  '02387',
  '02980',
];

interface RedemptionDate {
  day: string;
  month: string;
  year: string;
}

export interface SubAccountWithErcConcessionData {
  subAccountNumber: string,
  interestRate: number,
  productEndDate: Date,
  ercAllowanceRemaining: string,
  remainingBalance: string,
  creditsInPeriod: string,
  totalAllowance: string,
  ercInd: boolean,
  remainingMortgageTermYears: number,
  remainingMortgageTermMonths: number,
  ercStartDate: Date
}

export const mapERCConcessions = (
  {
    ERCConcessionAmountRemaining,
    ERCConcessionCreditsInERCPeriod,
    ERCConcessionAllowance,
    ERCInd,
    ERCYearStartDate,
    subAccountNumber: accountNum
  }: ERCConcessionAmountDetail,
  overview: Overview,) => {

  const overviewDataForSubAccount = overview?.overview.subAccounts.find(
    (subAccount) =>
      subAccount.subAccountNumber.padStart(2, '0') === accountNum,
  );


  const { interestRate, productEndDate, currentBalance } = overviewDataForSubAccount || {};

  return {
    subAccountNumber: accountNum,
    interestRate,
    productEndDate: productEndDate ? new Date(productEndDate) : undefined,
    ercAllowanceRemaining: Number(ERCConcessionAmountRemaining) > 0 ? ERCConcessionAmountRemaining : '0',
    remainingBalance: currentBalance === undefined ? '' : String(currentBalance),
    creditsInPeriod: ERCConcessionCreditsInERCPeriod,
    totalAllowance: ERCConcessionAllowance,
    ercInd: ERCInd === 'Y',
    remainingMortgageTermYears: overviewDataForSubAccount?.remainingMortgageTermYears,
    remainingMortgageTermMonths: overviewDataForSubAccount?.remainingMortgageTermMonths,
    ercStartDate: ERCYearStartDate ? new Date(ERCYearStartDate) : undefined
  };
}

export const mapSubAccounts = (
  redemptionData: Redemption,
  overview: Overview,
): SubAccountWithErcConcessionData[] => {
  const { ercConcessionAmountDetails } = redemptionData;

  const subAccountNumbers = new Set(
    [...ercConcessionAmountDetails].map(
      ({ subAccountNumber }) => subAccountNumber,
    ),
  );

  const mappedSubAccounts = Array.from(subAccountNumbers).map(
    (subAccountNumber) => {

      let ercConcessions: SubAccountWithErcConcessionData | undefined;

      if (ercConcessionAmountDetails) {
        const rawERCConcessions = ercConcessionAmountDetails.find(
          (subAccount) =>
            subAccount.subAccountNumber === subAccountNumber
        )
        if (rawERCConcessions) {
          ercConcessions = mapERCConcessions(rawERCConcessions, overview);
        }
      }

      return ercConcessions;
    },
  )

  return mappedSubAccounts.sort(
    (a, b) =>
      parseInt(a.subAccountNumber, 10) - parseInt(b.subAccountNumber, 10),
  );
};

const useERC = () => {
  const [
    overview,
    {
      isLoading: overviewIsLoading,
      isError: overviewIsError,
      data: overviewData,
    },
  ] = useOverviewMutation();
  const [
    redemption,
    {
      isLoading: redemptionIsLoading,
      isError: redemptionIsError,
      data: redemptionData,
    },
  ] = useRedemptionMutation();

  const overviewState = useAppSelector<Overview>(
    (appState) => appState.overview,
  );

  const navigate = useNavigate();

  const makeRedemptionRequest = useCallback(
    async (
      requestedRedemptionDate: RedemptionDate,
      onSuccess?: () => void,
    ) => {
      const requestType = 'E';
      const requestRedemptionDate = `${requestedRedemptionDate.year}-${requestedRedemptionDate.month.padStart(2, '0')}-${requestedRedemptionDate.day.padStart(2, '0')}`;

      const response = await redemption({
        redemptionDate: requestRedemptionDate,
        requestType,
      });

      if (!response || !response.data) {
        navigate(routes.OverpaymentAllowanceTechnicalError);
        return;
      }

      if ('errors' in response.data && response.data.errors.length > 0) {
        const unableToShowFigures = response.data.errors.some(
          ({ reasonCode }) => UNABLE_TO_SHOW_ERROR_CODES.includes(reasonCode),
        );

        if (unableToShowFigures) {
          navigate(routes.OverpaymentAllowanceAccountWarning);
          return;
        }

        navigate(routes.OverpaymentAllowanceTechnicalError);
        return;
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    [navigate, redemption],
  );

  useEffect(() => {
    const refetchOverview = async () => {
      await overview();
    };

    if (!overviewData && !overviewState.overview) {
      refetchOverview();
    }

    if (overviewIsError) {
      navigate(routes.OverpaymentAllowanceTechnicalError)
    }
  }, [overview, overviewData, overviewState, overviewIsError, navigate]);

  const requestERCData = useCallback(
    async (requestedRedemptionDate: {
      day: string;
      month: string;
      year: string;
    }) => {
      await makeRedemptionRequest(requestedRedemptionDate);
    },
    [makeRedemptionRequest],
  )


  // Map sub accounts from redemption into format needed for the UI
  const subAccounts = useMemo(() => {
    if (!redemptionData) return [];
    if (!overviewState.overview) return [];
    return mapSubAccounts(redemptionData, overviewState);
  }, [redemptionData, overviewState]);

  const {
    ercConcessionAmountDetails
  } = redemptionData || {};

  return {
    requestERCData,
    isLoading: redemptionIsLoading || overviewIsLoading,
    isError: redemptionIsError || overviewIsError,
    subAccounts,
    ercConcessionAmountDetails
  };
};

export default useERC;
