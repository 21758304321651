import React, { useState } from 'react';

import { Accordion, Paragraph, Strong } from '@constellation/core';
import formatDate from 'client/routes/mortgageOverview/utils/formatDate';
import { logTealiumButtonClickEvent } from 'client/tealium';

import formatRedemptionDateObject from '../../utils/formatRedemptionDateObject';
import ErcChargeNotification from '../ErcChargeNotification/ErcChargeNotification';
import ErcNoChargeNotification from '../ErcNoChargeNotification/ErcNoChargeNotification';
import ErcSubAccountBreakdownPanel from '../ErcSubAccountBreakdownPanel/ErcSubAccountBreakdownPanel';

interface RedemptionDate {
  day: string;
  month: string;
  year: string;
}

interface Bands {
  percentage: number;
  expiryDate: Date;
}

interface Fees {
  amount: string;
  expiryDate: Date;
}

export interface MappedSubAccount {
  subAccountNumber: string;
  interestRate: number;
  productEndDate: string;
  bands: Bands[];
  fees: Fees[];
}

interface ErcSubAccountAccordionProps {
  redemptionDate: RedemptionDate;
  subAccount: MappedSubAccount;
}

function ErcSubAccountAccordion({
  redemptionDate,
  subAccount,
}: ErcSubAccountAccordionProps) {
  const [open, setOpen] = useState(false);
  const { subAccountNumber, bands, fees, interestRate, productEndDate } =
    subAccount;

  const interestRateInfo = productEndDate
    ? `${interestRate}% until ${formatDate(productEndDate)}`
    : `${interestRate}%`;

  return (
    <Accordion
      data-testid={`sub-account-${subAccountNumber}-accordion`}
      label={`Sub-account ${subAccountNumber}`}
      variation="inpage1"
      open={open}
      onChange={() => {
        logTealiumButtonClickEvent(
          `accordion/sub-account-${subAccount.subAccountNumber}-${open ? 'close' : 'open'}`,
        );
        setOpen((current) => !current);
      }}
    >
      <Paragraph data-testid={`sub-account-${subAccountNumber}-interest-info`}>
        Current interest rate:{' '}
        <Strong data-testid={`sub-account-${subAccountNumber}-interest`}>
          {interestRateInfo}
        </Strong>
      </Paragraph>
      {fees.length ? (
        <>
          <ErcChargeNotification
            redemptionDate={formatRedemptionDateObject(redemptionDate)}
            charge={fees[fees.length - 1].amount}
          />
          <ErcSubAccountBreakdownPanel
            ercBands={bands}
            subAccountNumber={subAccountNumber}
          />
        </>
      ) : (
        <ErcNoChargeNotification />
      )}
    </Accordion>
  );
}

export default ErcSubAccountAccordion;
