import { AppContent } from '../AppContent';

const overpaymentsAllowance: AppContent['overpaymentsAllowance'] = {
  heading: 'Your overpayment allowance',
  summaryText: "Here's a summary of your overpayment allowance for the year.",
  backButtonLabel: 'Back',

  findOutHowToPayButton: 'Find out how to pay',
  returnToMortgageOverviewLink: 'Return to mortgage overview',

  infoBox: {
    overpaymentDescription:
      'Your overpayment allowance is the amount you can overpay, before paying an early repayment charge (ERC).',
    howWeWorkOutLinkLabel: 'How we work out your allowance',
  },

  subaccountsInfo: {
    heading: 'Your mortgage has ',
    heading2: ' sub-accounts.',
    text: "Each sub-account has it's own overpayment allowance, so it's a good idea to check your allowance before you make an overpayment.",
    dialogLink: 'Important info about sub-accounts',
  },

  howWeWorkOutAllowanceDialog: {
    fixedRateHeading: 'If you have a fixed or tracker interest rate',
    fixedRateText1:
      'We refresh your overpayment allowance each year on 01 Jan, based on your outstanding balance at the time.',
    fixedRateText2:
      "Your remaining allowance might not include overpayments made in the past few days. It won't include other overpayments you've set up but not yet paid.",
    fixedRateText3:
      "You can still make overpayments if you exceed your 10% allowance, but you'll pay ERC on the amount you overpay.",
    variableRateHeading: 'If you have a variable interest rate',
    variableRateText1:
      'You can overpay any part of your mortgage with a variable interest rate as much as you like, without any ERCs.',
  },

  subaccountsDialog: {
    text1:
      'We add sub-accounts to a mortgage if changes are made after taking the initial loan, like borrowing more for home improvements.',
    text2: 'Each sub-account has its own overpayment allowance.',
    text3:
      "If you make an overpayment and don't tell us which sub-account you'd like to overpay, we'll split it across your whole mortgage in the same way we do with your normal monthly payments.",
    text4:
      'Your sub-accounts could have different interest rates and repayment terms, so you might want to decide which ones to overpay first.',
  },

  notifications: {
    singleSubAccount: {
      overpaymentAllowedText:
        'You can still make overpayments with no charges.',
      overpayWholeBalanceText:
        'You can overpay the whole balance with no charges.',
      usedAllowanceText: "You've used your allowance for this year.",
    },
    multiSubAccount: {
      overpaymentAllowedText:
        'You can still make overpayments on this sub-account with no charges.',
      overpayWholeBalanceText:
        'You can overpay the whole balance on this sub-account with no charges.',
      usedAllowanceText:
        "You've already used your overpayment allowance on this sub-account.",
    },
  },

  accountPanel: {
    totalAllowance: 'Your 10% allowance for',
    allowanceUsed: "So far you've used:",
    accordionLabel: 'Sub-account',
    currentInterestLabel: 'Current interest rate:',
    remainingBalanceLabel: 'Remaining balance:',
    remainingTermLabel: 'Remaining term:',
    allowanceRemainingLabel: 'Allowance remaining:',
  },
};

export default overpaymentsAllowance;
