import styled from "styled-components";

/* Constellations ButtonPattern requires a chevron left to split the children to the left and right of the screen
   This splits the children to the left and right of the screen without the need for the chevron */
const StyledButtonPattern = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.color_border_default_2};
  padding-top: ${({ theme }) => theme.spacing_06};

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing_04};
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }: any) => theme.breakpoint_md}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing_06};

    & > * {
      margin-bottom: 0;
    }
  }
`;

export default StyledButtonPattern;