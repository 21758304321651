import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export const ErcBreakdownList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-left: 1rem;
  margin-bottom: 0;
  list-style-type: none;
`;

export const ErcBreakdownItem = styled.li`
  display: flex;
  flex-direction: row;
  padding-bottom: ${({ theme }) => theme.spacing_03};
  margin-bottom: 0;
  border-left: 2px dashed grey;
  box-sizing: border-box;

  :last-child {
    border-left: 2px solid transparent;
    padding-bottom: 0.5rem;
  }

  @media (min-width: ${({ theme }: any) => theme.breakpoint_md}) {
    :last-child {
      border-left: 2px solid transparent;
      padding-bottom: 0rem;
    }
  }
`;

export const ErcBreakdownItemIcon = styled.span`
  background-color: #f1f1f1;
  width: ${({ theme }) => theme.spacing_05};
  height: ${({ theme }) => theme.spacing_05};
  padding: 0;
  margin-left: calc(-${({ theme }) => theme.spacing_03} - 1px);
  margin-right: ${({ theme }) => theme.spacing_03};
  box-shadow: 0 0 0 2px #f1f1f1;
  text-align: center;
`;

export const ErcBreakdownItemDescription = styled.div``;
