import React, { useEffect, useState } from 'react';

import {
  Button,
  ContentGroup,
  Dialog,
  Grid,
  GridItem,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  List,
  ListItem,
  Main,
  Paragraph,
  TextField,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  logTealiumBlurEvent,
  logTealiumButtonClickEvent,
  logTealiumNavEvent,
} from 'client/tealium';
import { Field, Form, Formik } from 'formik';

import parseAndCalculateGrossYield from './calculations/parseAndCalculateGrossYield';
import parseAndCalculateNetYield from './calculations/parseAndCalculateNetYield';
import formatCurrency from './formatters/formatCurrency';
import formatIntegerOrDecimalCurrency from './formatters/formatIntegerOrDecimalCurrency';
import { StyledBox, StyledContainer } from './RentalYieldCalculator.styled';
import RentalYieldCalculatorPageContent from './RentalYieldCalculatorPage.config';
import isValidNonZeroCurrencyAmount from './validators/isValidNonZeroCurrencyAmount';

interface FormValues {
  propertyPurchasePrice: string;
  monthlyRentalIncome: string;
  annualRunningCosts: string;
}

function RentalYieldCalculatorPage() {
  const [showWhyDialog, setShowWhyDialog] = useState(false);
  const [showConsiderationsDialog, setShowConsiderationsDialog] =
    useState(false);
  const navigate = useNavigate();
  const [netYield, setNetYield] = useState('');
  const [grossYield, setGrossYield] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const {
    backButtonLabel,
    pageHeading,
    supportingText,
    whyLinkLabel,
    propertyPurchasePriceField,
    monthlyRentalIncomeField,
    annualRunningCostsField,
    whyCalculateDialog,
    considerationsDialog,
    buttonLabel,
    alternateButtonLabel,
    grossYieldPanel,
    netYieldPanel,
  } = useContent<RentalYieldCalculatorPageContent>();

  useEffect(() => {
    logTealiumNavEvent(
      'Rental Yield Calculator',
      '1',
      undefined,
      'Pre-Application',
    );
  }, []);

  let focusTimerStart: number;

  const handleTimerEnd = (fieldName: string) => {
    const dwellTimeInMs = Date.now() - focusTimerStart;
    logTealiumBlurEvent(fieldName, dwellTimeInMs);
  };

  const validateForm = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!isValidNonZeroCurrencyAmount(values.propertyPurchasePrice)) {
      errors.propertyPurchasePrice = propertyPurchasePriceField.error;
    }

    if (!isValidNonZeroCurrencyAmount(values.monthlyRentalIncome)) {
      errors.monthlyRentalIncome = monthlyRentalIncomeField.error;
    }

    return errors;
  };

  const resultContainerWidth = grossYield && netYield ? 6 : 12;
  const calculateButtonLabel = hasSubmitted
    ? alternateButtonLabel
    : buttonLabel;
  const calculateTaggingLabel = hasSubmitted ? 'recalculate' : 'calculate';

  const onSubmit = (values: FormValues) => {
    setGrossYield('');
    setNetYield('');

    const calculatedGrossYield = parseAndCalculateGrossYield(
      values.propertyPurchasePrice,
      values.monthlyRentalIncome,
    );

    setGrossYield(calculatedGrossYield.toString());

    if (isValidNonZeroCurrencyAmount(values.annualRunningCosts)) {
      const calculatedNetYield = parseAndCalculateNetYield(
        values.propertyPurchasePrice,
        values.monthlyRentalIncome,
        values.annualRunningCosts,
      );

      setNetYield(calculatedNetYield.toString());
    }

    setHasSubmitted(true);
  };

  return (
    <Main>
      <Dialog
        title={whyCalculateDialog.title}
        open={showWhyDialog}
        onClose={() => {
          logTealiumButtonClickEvent(
            'button/why-calculate-rental-yield-dialog-close',
          );
          setShowWhyDialog(false);
        }}
      >
        <Paragraph>{whyCalculateDialog.paragraph1}</Paragraph>
        <Paragraph marginBottom="02">{whyCalculateDialog.paragraph2}</Paragraph>
        <List marginBottom="none">
          {whyCalculateDialog.list.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key -- Static list order and size will not change dynamically
            <ListItem key={`why-calculate-dialog-list-item-${idx}`}>
              {item}
            </ListItem>
          ))}
        </List>
      </Dialog>

      <Dialog
        title={considerationsDialog.title}
        open={showConsiderationsDialog}
        onClose={() => {
          logTealiumButtonClickEvent(
            'button/what-to-include-here-dialog-close',
          );
          setShowConsiderationsDialog(false);
        }}
      >
        <Paragraph marginBottom="02">
          {considerationsDialog.paragraph1}
        </Paragraph>
        <List>
          {considerationsDialog.list.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key -- Static list order and size will not change dynamically
            <ListItem key={`considerations-dialog-list-item-${idx}`}>
              {item}
            </ListItem>
          ))}
        </List>
        <Paragraph marginBottom="none">
          {considerationsDialog.paragraph2}
        </Paragraph>
      </Dialog>

      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent('button/back');
            navigate(routes.MortgageOverview);
          }}
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>

      <Hr marginTop="none" marginBottom="04" />

      <Heading marginBottom="04" marginTop="none" size="s5" as="h1">
        {pageHeading}
      </Heading>
      <Paragraph marginBottom="04">{supportingText}</Paragraph>
      <Paragraph marginBottom="04">
        <Link
          as="button"
          onClick={() => {
            logTealiumButtonClickEvent('button/why-calculate-rental-yield');
            setShowWhyDialog(true);
          }}
        >
          {whyLinkLabel}
        </Link>
      </Paragraph>

      <Formik
        initialValues={{
          propertyPurchasePrice: '',
          monthlyRentalIncome: '',
          annualRunningCosts: '',
        }}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          handleSubmit,
          handleBlur,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Field
              name="propertyPurchasePrice"
              value={values.propertyPurchasePrice}
              label={propertyPurchasePriceField.label}
              marginBottom="04"
              onFocus={() => {
                focusTimerStart = Date.now();
              }}
              placeholder="£"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'propertyPurchasePrice',
                  formatCurrency(e.target.value),
                );
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                setFieldValue(
                  'propertyPurchasePrice',
                  formatIntegerOrDecimalCurrency(e.target.value),
                );
                handleBlur(e);
                handleTimerEnd('propertyPurchasePrice');
              }}
              error={
                touched.propertyPurchasePrice && errors.propertyPurchasePrice
              }
              data-testid="redemption-date-field"
              as={TextField}
            />
            <Field
              name="monthlyRentalIncome"
              value={values.monthlyRentalIncome}
              label={monthlyRentalIncomeField.label}
              marginBottom="04"
              error={touched.monthlyRentalIncome && errors.monthlyRentalIncome}
              placeholder="£"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'monthlyRentalIncome',
                  formatCurrency(e.target.value),
                );
              }}
              onFocus={() => {
                focusTimerStart = Date.now();
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                setFieldValue(
                  'monthlyRentalIncome',
                  formatIntegerOrDecimalCurrency(e.target.value),
                );
                handleBlur(e);
                handleTimerEnd('monthlyRentalIncome');
              }}
              as={TextField}
            />
            <Field
              name="annualRunningCosts"
              value={values.annualRunningCosts}
              label={annualRunningCostsField.label}
              marginBottom="04"
              placeholder="£"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'annualRunningCosts',
                  formatCurrency(e.target.value),
                );
              }}
              onFocus={() => {
                focusTimerStart = Date.now();
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                setFieldValue(
                  'annualRunningCosts',
                  formatIntegerOrDecimalCurrency(e.target.value),
                );
                handleBlur(e);
                handleTimerEnd('annualRunningCosts');
              }}
              supportiveText={
                <>
                  <Paragraph marginBottom="03">
                    {annualRunningCostsField.supportiveText}
                  </Paragraph>
                  <Link
                    as="button"
                    type="button"
                    onClick={() => {
                      logTealiumButtonClickEvent('button/what-to-include-here');
                      setShowConsiderationsDialog(true);
                    }}
                  >
                    {annualRunningCostsField.whatToIncludeLinkLabel}
                  </Link>
                </>
              }
              as={TextField}
            />
            <Button
              type="submit"
              onClick={() => {
                logTealiumButtonClickEvent(`button/${calculateTaggingLabel}`);
                handleSubmit();
              }}
            >
              {calculateButtonLabel}
            </Button>
          </Form>
        )}
      </Formik>
      <ContentGroup marginTop="05" marginBottom="none">
        <Grid>
          {grossYield && (
            <GridItem sm={resultContainerWidth}>
              <StyledBox>
                <Heading marginBottom="03" size="s2">
                  {grossYieldPanel.title}
                </Heading>
                <Paragraph marginBottom="03" size="s1">
                  {grossYieldPanel.supportiveText}
                </Paragraph>
                <Heading size="s7" marginBottom="none">
                  {grossYield}%
                </Heading>
              </StyledBox>
            </GridItem>
          )}
          {netYield && (
            <GridItem sm={resultContainerWidth}>
              <StyledBox>
                <Heading marginBottom="03" size="s2">
                  {netYieldPanel.title}
                </Heading>
                <Paragraph marginBottom="03" size="s1">
                  {netYieldPanel.supportiveText}
                </Paragraph>
                <Heading size="s7" marginBottom="none">
                  {netYield}%
                </Heading>
              </StyledBox>
            </GridItem>
          )}
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default RentalYieldCalculatorPage;
