import React from 'react';

import {
  Button,
  ButtonPattern,
  DateField,
  Dialog,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import {
  logTealiumBlurEvent,
  logTealiumButtonClickEvent,
} from 'client/tealium';
import isValidRedemptionDate from 'client/validation/isValidRedemptionDate';
import { Field, Formik } from 'formik';

import { RedemptionContent } from '../../RedemptionPage.config';

interface FormValues {
  redemptionDate: { day: string; month: string; year: string };
}

interface DateSelectDialogProps {
  open: boolean;
  onClose: () => void;
  redemptionDate: {
    day: string;
    month: string;
    year: string;
  };
  onSubmit: (values: FormValues) => void;
}

function DateSelectDialog({
  open,
  onClose,
  redemptionDate,
  onSubmit,
}: DateSelectDialogProps) {
  const { dateDialog } = useContent<RedemptionContent>();

  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      redemptionDate: string;
    }> = {};

    const { day, month, year } = values.redemptionDate;

    if (!isValidRedemptionDate(day, month, year)) {
      errors.redemptionDate = dateDialog.field.error;
    }

    return errors;
  };

  let focusTimerStart: number;

  const handleTimerEnd = (fieldName: string) => {
    const dwellTimeInMs = Date.now() - focusTimerStart;
    logTealiumBlurEvent(fieldName, dwellTimeInMs);
  };

  return (
    <Dialog
      open={open}
      title={dateDialog.heading}
      onClose={onClose}
      data-testid="redemption-date-dialog"
    >
      <Formik
        initialValues={{
          redemptionDate,
        }}
        validate={validateForm}
        onSubmit={onSubmit}
      >
        {({ values, setValues, handleBlur, handleSubmit, touched, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              label={dateDialog.field.label}
              supportiveText={dateDialog.field.supportiveText}
              name="redemptionDate"
              marginBottom="05"
              day={values.redemptionDate.day}
              month={values.redemptionDate.month}
              year={values.redemptionDate.year}
              onChange={(newRedemptionDate: {
                day: string;
                month: string;
                year: string;
              }) => {
                setValues({ ...values, redemptionDate: newRedemptionDate });
              }}
              onFocus={() => {
                focusTimerStart = Date.now();
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                handleBlur(e);
                handleTimerEnd(e.target.name);
              }}
              error={
                (touched.redemptionDate ||
                  (touched['redemptionDate-day'] ||
                    touched['redemptionDate-month'] ||
                    touched['redemptionDate-year'])) &&
                errors.redemptionDate &&
                dateDialog.field.error
              }
              data-testid="redemption-date-field"
              as={DateField}
            />
            <ButtonPattern marginBottom="none">
              <Button
                type="submit"
                data-testid="redemption-date-dialog-confirm-button"
                onClick={() => {
                  logTealiumButtonClickEvent('button/change-date-confirm');
                }}
              >
                {dateDialog.confirmButtonLabel}
              </Button>
              <Link
                as="button"
                type="button"
                onClick={onClose}
                data-testid="redemption-date-dialog-cancel-button"
              >
                {dateDialog.cancelButtonLabel}
              </Link>
            </ButtonPattern>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default DateSelectDialog;
