import React from 'react';

import { Notification } from '@constellation/core';

interface NotificationProps {
  ercAllowanceRemaining: string;
  ercsApplyIndicator: boolean;
  overpaymentAllowedText: string;
  overpayWholeBalanceText: string;
  usedAllowanceText: string;
}

function OverpaymentsAllowanceNotification({
  ercAllowanceRemaining,
  ercsApplyIndicator,
  overpaymentAllowedText,
  overpayWholeBalanceText,
  usedAllowanceText,
}: NotificationProps): React.JSX.Element {
  const canOverpay = Number(ercAllowanceRemaining) > 0 || !ercsApplyIndicator;

  return (
    <Notification
      headingLevel={2}
      sentiment={canOverpay ? 'success' : 'warning'}
      data-testid="overpayment-allowance-notification"
    >
      {canOverpay && ercsApplyIndicator && overpaymentAllowedText}
      {canOverpay && !ercsApplyIndicator && overpayWholeBalanceText}
      {!canOverpay && usedAllowanceText}
    </Notification>
  );
}

export default OverpaymentsAllowanceNotification;
