// Auth
export const SignedOut = '/signed-out';
export const SignIn = '/sign-in';

// General Errors
export const Error = '/error';
export const ServiceUnavailable = '/service-unavailable';

// Make a payment
export const MakePayment = '/make-payment';
export const MakePaymentBankTransfer = '/make-payment/bank-transfer';
export const MakePaymentPhone = '/make-payment/phone';

// Overview and secondary pages
export const MortgageOverview = '/mortgage-overview';
export const BorrowMore = '/borrow-more';
export const Overpayments = '/overpayments';
export const ProductTransfer = '/product-transfer';
export const RentalYield = '/rental-yield';

// Overpayment Allowance
export const OverpaymentAllowance = '/overpayment-allowance';
export const OverpaymentAllowanceAccountWarning = '/overpayment-allowance/unavailable';
export const OverpaymentAllowanceTechnicalError = '/overpayment-allowance/error';

// Redemption
export const Redemption = '/redemption';
export const RedemptionAlreadyRequested = '/redemption/already-requested';
export const RedemptionError = '/redemption/error';
export const RedemptionSuccess = '/redemption/success';
export const RedemptionUnavailable = '/redemption/unavailable';

// Statement Requests
export const DebitChangeRequest = '/requests/change-direct-debit';
export const DuplicateStatement = '/requests/duplicate-statement';
export const MortgageInterest = '/requests/certificate-of-interest';
export const RequestOverpayments = '/requests/overpayments-allowance';
export const RedemptionStatement = '/requests/redemption-statement';
export const SuccessfulRequest = '/requests/success';

// please keep alphabetically sorted
export const AllRoutes = [
  BorrowMore,
  DebitChangeRequest,
  DuplicateStatement,
  Error,
  MortgageInterest,
  MortgageOverview,
  Overpayments,
  OverpaymentAllowance,
  OverpaymentAllowanceAccountWarning,
  OverpaymentAllowanceTechnicalError,
  ProductTransfer,
  RedemptionStatement,
  RequestOverpayments,
  ServiceUnavailable,
  SignedOut,
  SignIn,
  SuccessfulRequest,
  MakePayment,
  MakePaymentBankTransfer,
  MakePaymentPhone,
  Redemption,
  RedemptionSuccess,
  RedemptionError,
  RedemptionAlreadyRequested,
  RedemptionUnavailable,
];
