import React, { useEffect } from "react"

import { Main, Notification, Paragraph, Link, Grid, GridItem, ButtonPattern, Button } from "@constellation/core"
import { useContent } from "@interstellar/react-app-content";
import { useNavigate } from "@interstellar/react-app-routing";
import { logTealiumButtonClickEvent, logTealiumNavEvent } from "client/tealium";

import { OverpaymentsAllowanceAccountWarningContent } from "./OverpaymentsAllowanceAccountWarningPage.config";
import * as routes from '../../../manifest';

function OverpaymentsAllowanceAccountWarningPage(): React.JSX.Element {
  const {
    notificationHeading,
    notificationSupportText1,
    notificationSupportText2,
    contactNumber,
    returnToMortgageOverviewLabel
  } = useContent<OverpaymentsAllowanceAccountWarningContent>();

  useEffect(() => {
    logTealiumNavEvent(
      'Overpayment allowance - Unable to show figures',
      '2',
      undefined,
      'Pre-Application',
    );
  }, []);

  const navigate = useNavigate();

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Notification
            sentiment="warning"
            headingLevel={2}
            heading={notificationHeading}
          >
            <Paragraph>{notificationSupportText1}{' '}
              <Link
                onClick={() => logTealiumButtonClickEvent('link/helpline')}
                href={`tel: ${contactNumber}`}>{contactNumber}</Link>.
            </Paragraph>
            <Paragraph marginBottom="none">{notificationSupportText2}</Paragraph>
          </Notification>

          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent('button/return-to-mortgage-overview')
                navigate(routes.MortgageOverview)
              }}
            >{returnToMortgageOverviewLabel}</Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  )
}

export default OverpaymentsAllowanceAccountWarningPage;