import React, { useState } from "react";

import { Accordion, Paragraph, Strong, Text } from "@constellation/core";
import { useContent } from "@interstellar/react-app-content";
import { formatCurrency } from "client/routes/redemption/utils/formatCurrency";
import { logTealiumButtonClickEvent } from "client/tealium";

import { SubAccountWithErcConcessionData } from "../../hooks/useERC";
import { StyledParagraph } from "../../OverpaymentsAllowance.styled";
import { OverpaymentsAllowanceContent } from "../../OverpaymentsAllowancePage.config";
import OverpaymentsAllowanceNotification from '../overpaymentsAllowanceNotification/OverpaymentsAllowanceNotification';

interface MultipleSubAccountAccordionProps {
  subAccount: SubAccountWithErcConcessionData;
}

export function formatInterestInfo(date: Date) {
  return date
    ? ` until ${Intl.DateTimeFormat('en-GB', { dateStyle: 'long' }).format(date)}`
    : '';
}

function MultipleSubAccountAccordion({
  subAccount,
}: MultipleSubAccountAccordionProps): React.JSX.Element {
  const {
    accountPanel,
    notifications: { multiSubAccount },
  } = useContent<OverpaymentsAllowanceContent>();

  const [open, setOpen] = useState(false);

  return (
    <Accordion
      key={`subaccount-accordion-${subAccount.subAccountNumber}`}
      data-testid={`sub-account-${subAccount.subAccountNumber}-accordion`}
      label={`${accountPanel.accordionLabel} ${subAccount.subAccountNumber}`}
      variation="inpage1"
      open={open}
      onChange={() => {
        logTealiumButtonClickEvent(
          `accordion/sub-account-${subAccount.subAccountNumber}-${open ? 'close' : 'open'}`,
        );
        setOpen((current) => !current);
      }}
    >
      <Paragraph marginBottom="none" size="s1" data-testid="multi-subaccount-current-interest-label">
        {accountPanel.currentInterestLabel}{' '}
        <Strong
          data-testid={`account-interest-rate-${subAccount.subAccountNumber}`}
        >
          {subAccount.interestRate}%
          {formatInterestInfo(subAccount.productEndDate)}
        </Strong>
      </Paragraph>

      <Paragraph marginTop="none" marginBottom="none" size="s1" data-testid="multi-subaccount-remaining-balance-label">
        {accountPanel.remainingBalanceLabel}{' '}
        <Strong
          data-testid={`remaining-balance-${subAccount.subAccountNumber}`}
        >
          {subAccount.remainingBalance ? formatCurrency(subAccount.remainingBalance) : ''}
        </Strong>
      </Paragraph>

      <Paragraph marginTop="none" size="s1" data-testid="multi-subaccount-remaining-term-label">
        {accountPanel.remainingTermLabel}{' '}
        <Strong data-testid={`remaining-term-${subAccount.subAccountNumber}`}>
          {subAccount.remainingMortgageTermYears} years and{' '}
          {subAccount.remainingMortgageTermMonths} months
        </Strong>
      </Paragraph>

      <OverpaymentsAllowanceNotification
        ercAllowanceRemaining={subAccount.ercAllowanceRemaining}
        ercsApplyIndicator={subAccount.ercInd}
        overpaymentAllowedText={multiSubAccount.overpaymentAllowedText}
        overpayWholeBalanceText={multiSubAccount.overpayWholeBalanceText}
        usedAllowanceText={multiSubAccount.usedAllowanceText}
      />

      {subAccount.ercInd &&
        <>
          <StyledParagraph>
            <Text data-testid="multi-subaccount-total-allowance-label">
              {accountPanel.totalAllowance}{' '}
              {subAccount.ercStartDate?.getFullYear()}:
            </Text>
            <Strong
              data-testid={`erc-allowance-value-${Number(subAccount.subAccountNumber)}`}
            >
              {formatCurrency(subAccount.totalAllowance)}
            </Strong>
          </StyledParagraph>

          <StyledParagraph>
            <Text data-testid="multi-subaccount-allowance-used-label">{accountPanel.allowanceUsed}</Text>
            <Strong
              data-testid={`erc-credits-value-${Number(subAccount.subAccountNumber)}`}
            >
              {formatCurrency(subAccount.creditsInPeriod)}
            </Strong>
          </StyledParagraph>

          <StyledParagraph marginBottom="02">
            <Text data-testid="multi-subaccount-allowance-remaining-label">{accountPanel.allowanceRemainingLabel}</Text>
            <Strong
              data-testid={`erc-amount-remaining-value-${Number(subAccount.subAccountNumber)}`}
            >
              {formatCurrency(subAccount.ercAllowanceRemaining)}
            </Strong>
          </StyledParagraph>
        </>
      }
    </Accordion >
  );
}

export default MultipleSubAccountAccordion;
