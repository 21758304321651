import { Paragraph } from "@constellation/core";
import styled from "styled-components";


export const StyledParagraph = styled(Paragraph)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;