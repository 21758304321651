import { NavigateFunction } from '@interstellar/react-app-routing';
import { AxiosError, AxiosResponse } from 'axios';
import * as routes from 'client/routes/manifest';

import axiosInstance from './axiosInstance';

export const onFulfilled =
  (navigate: NavigateFunction) => (response: AxiosResponse) => {
    // 2xx status codes
    // Check the response type is as expected (json object)
    const contentTypeHeader = response.headers['content-type'];
    if (
      contentTypeHeader &&
      contentTypeHeader.includes('application/json') &&
      typeof response.data === 'object'
    ) {
      return response;
    }

    // Redirect to the error page for an unexpected response type
    navigate('/error');
    return Promise.reject(new Error('Invalid response type'));
  };

export const onRejected =
  (
    navigate: NavigateFunction,
    setShowVersionMismatchDialog: React.Dispatch<React.SetStateAction<boolean>>,
  ) =>
    (err: AxiosError) => {
      if (
        window.location.pathname.includes(routes.SignIn) &&
        (err.response.status === 400 || err.response.status === 401)
      ) {
        return undefined;
      }

      if (
        window.location.pathname.includes(routes.Redemption) &&
        (err.response.status === 500 || err.response.status === 400)
      ) {
        return undefined;
      }

      // If we're trying to access overpayment-allowance and the call to overview fails
      if (
        window.location.pathname.includes(routes.OverpaymentAllowance) &&
        (err.response.status === 500 || err.response.status === 400)
      ) {
        return undefined;
      }

      // show mismatch error dialog & return the error when 409 Conflict error code occurs
      if (err.response.status === 409) {
        setShowVersionMismatchDialog(true);
        return Promise.reject(err);
      }

      // If we're doing a healthcheck skip redirecting
      if (err.config?.url?.endsWith('applicationAvailability')) {
        return undefined;
      }

      // Fail silently if the sign-out call fails - we still delete the cookie
      if (err.config?.url?.endsWith('sign-out')) {
        return undefined;
      }

      // 401 redirect to sign in
      if (err.response.status === 401) {
        navigate('/sign-in');
        return undefined;
      }

      // Everything else is a non-2xx status code. Redirect to the error page
      navigate('/error');
      return Promise.reject(err);
    };

export const registerErrorHandler = (
  navigate: NavigateFunction,
  setShowVersionMismatchDialog: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  axiosInstance.interceptors.response.use(
    onFulfilled(navigate),
    onRejected(navigate, setShowVersionMismatchDialog),
  );
};
