import React, { useEffect } from "react";

import { Notification, Text, Main, Button, ButtonPattern, GridItem, Grid } from "@constellation/core";
import { useContent } from "@interstellar/react-app-content";
import { useNavigate } from "@interstellar/react-app-routing";
import { logTealiumButtonClickEvent, logTealiumNavEvent } from "client/tealium";

import { OverpaymentAllowanceTechnicalErrorContent } from "./OverpaymentsAllowanceTechnicalErrorPage.config";
import * as routes from '../../../manifest';

function OverpaymentsAllowanceTechnicalErrorPage(): React.JSX.Element {
  const overpaymentAllowanceTechnicalErrorContent = useContent<OverpaymentAllowanceTechnicalErrorContent>();
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumNavEvent(
      'Overpayment allowance - Technical error',
      '2',
      undefined,
      'Pre-Application',
    );
  }, []);

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Notification
            marginBottom="05"
            heading={overpaymentAllowanceTechnicalErrorContent.notificationHeading}
            sentiment="warning"
            headingLevel={2}>
            <Text>{overpaymentAllowanceTechnicalErrorContent.notifcationsupportText}</Text>
          </Notification>

          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent('button/return-to-mortgage-overview')
                navigate(routes.MortgageOverview)
              }}
            >{overpaymentAllowanceTechnicalErrorContent.returnToMortgageOverviewLabel}</Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  )
}

export default OverpaymentsAllowanceTechnicalErrorPage;